<template>
    <modal ref="modalProducto" titulo="Producto Reemplazado" tamano="modal-lg" no-cancelar no-adicional>
        <div class="row mx-0 pt-4 pl-4 py-5">
            <div class="col-5 px-0">
                <img :src="producto.foto" width="266" height="266" class="obj-cover border br-12 img-product" />
            </div>
            <div class="col-xl col-lg col-md col-sm-12 col-12 ml-4">
                <p class="f-22 text-general f-500 nombre-producto mb-3">
                    <strong>{{funTexto()}}</strong>
                    <i class="f-15 text-white bg-green rounded-circle ml-2 p-2" :class="`${funIcono()}`" />
                </p>
                <p class="f-20 text-general f-500 nombre-producto">
                    {{ producto.nombre }}
                </p>
                <p class="my-2 f-300">
                    <span class="f-400">Presentación:</span> {{ producto.presentacion }}
                </p>
                <div class="row mx-0">
                    <p v-if="data.promocion" class="col-auto f-600 px-0 text-general f-24 px-0">
                        {{ convertMoney(funValorEnpedido(data.unidad_final)) }}
                    </p> 
                    <p v-else class="col-auto f-600 px-0 text-general f-24 px-0">
                        {{ convertMoney(funValorEnpedido(data.unidad_teorica)) }}
                    </p>
                    <p v-show="producto.promocion" class="col-auto f-600 ml-3 text-general2 f-24 px-0 descuento">
                        {{ convertMoney(funValorEnpedido(data.unidad_teorica)) }}
                    </p>
                </div>
                <div class="row mx-0 mt-3 mb-4">
                    <div v-show="data.promocion" class="bg-general px-2 f-12 br-20 py-1 text-white f-500 mr-3">
                        {{ promocion.texto }}
                    </div>
                    <p v-show="$usuario.tipo_impuesto === 1 && data.impuesto > 0" class="bg-general2 br-10 text-white f-12 text-center p-1">+ IVA</p>
                    <p v-show="$usuario.tipo_impuesto === 2 && data.impuesto > 0" class="bg-general2 br-10 text-white f-12 text-center p-1">IVA incluido</p>
                </div>
                <p class="text-gris2 f-15 my-4">
                    {{ producto.descripcion }}
                </p>
                
                <div class="row mx-0 mt-1">
                    <div class="col-auto px-0 f-15">
                        Cantidad
                    </div>  
                    <div class="col-auto ml-auto text-general px-0 f-600 f-15">
                        {{ formatNumero(data.cantidad) }}
                    </div>
                </div>
                <div v-if="data.estado == 22 || data.estado == 23 " class="row mx-0 mt-1">
                    <div class="col-auto px-0 f-15">
                        Cantidad antigua
                    </div>  
                    <div class="col-auto ml-auto text-general px-0 f-600 f-15">
                        {{ formatNumero(data.original.cantidad) }}
                    </div>
                </div>
                <div class="row mx-0 mt-1">
                    <div class="col-auto px-0 f-15">
                        Valor total 
                    </div>  
                    <div class="col-auto ml-auto text-general px-0 f-600 f-15">
                        {{ convertMoney(funValorEnpedido(data.total_final)) }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="data.estado == 21" class="row mx-0 pt-4 pl-4 py-5">
            <div class="col-5 px-0">
                <img :src="data.original.foto" width="266" height="266" class="obj-cover border br-12 img-product" />
            </div>
            <div class="col-xl col-lg col-md col-sm-12 col-12 ml-4">
                <p class="f-22 text-general f-500 nombre-producto mb-3">
                    <strong>Producto inicial</strong>
                    <i class="icon-bidirectional f-15 text-white bg-danger rounded-circle ml-2" />
                </p>  
                <p class="f-20 text-general f-500 nombre-producto">
                    {{ data.original.nombre }}
                </p>
                <p class="my-2 f-300">
                    <span class="f-400">Presentación:</span> {{ data.original.presentacion }}
                </p>
                <div class="row mx-0">
                    <p class="col-auto f-600 px-0 text-general f-24 px-0">
                        {{ convertMoney(funValorEnpedido(data.original.unidad_teorica)) }}
                    </p>
                </div>
                <p class="text-gris2 f-15 my-4">
                    {{ data.original.descripcion }}
                </p>
                <div class="row mx-0 mt-1">
                    <div class="col-auto px-0 f-15">
                        Cantidad
                    </div>  
                    <div class="col-auto ml-auto text-general px-0 f-600 f-15">
                        {{ formatNumero(data.original.cantidad) }}
                    </div>
                </div>
                <div class="row mx-0 mt-1">
                    <div class="col-auto px-0 f-15">
                        Valor total 
                    </div>  
                    <div class="col-auto ml-auto text-general px-0 f-600 f-15">
                        {{ convertMoney(funValorEnpedido(data.original.total_final)) }}
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    props:{
        data:{
            type:Object,
            default(){return {producto:{}}}
        },
    },
    data(){
        return {
            productosKit: false,
        }
    },
    computed: {
        producto(){
            return this.data.producto
        },
        promocion(){
            if(this.data.promocion){
                return this.data.data_promocion
            }else{
                return {}
            }
        },
        impuesto(){
            const i = this.data.impuesto
            return i ? i : 0
        },
        
    },
    methods:{
        toggle(){
            this.$refs.modalProducto.toggle();
        },
        funValorEnpedido(value){
            if(this.$usuario.tipo_impuesto === 0){
                return value
            }
            if(this.$usuario.tipo_impuesto === 1){
                return value
            }
            if(this.$usuario.tipo_impuesto === 2 || this.$usuario.tipo_impuesto === 3){
                return value * (1 + this.impuesto / 100)
            }
            return 0
        },
        funIcono(){
            switch (this.data.estado){
            case 51:
                return 'icon-trash-can-outline'
            case 22:
                return 'icon-plus' 
            case 23:
                return 'icon-minus' 
            case 21:
                return 'icon-bidirectional'
            case 12:
                return 'icon-cart-arrow-down'
            
            default:
                break;
            }
        },
        funTexto(){
            switch (this.data.estado){
            case 22:
            case 23:
                return 'Cambio de cantidad' 
            case 21:
                return 'Producto Remplazado'
            case 12:
                return 'Producto Agregado'
            
            default:
                'Producto'
                break;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.pill{
    min-width: 24px;
    height: 24px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    box-shadow: 0px 2px 6px #0000000D;
    background-color: #000000;
    border-radius: 16px;
}
.circled-heart{
    width:24px;
    height:24px;
    top:10px;
    right:16px;
    box-shadow: 0px 2px 6px #0000000D;
}
</style>